if($('.js-newsMoreBtn').length){
	var moreNum = $('.js-newsMoreBtn').parents().find('.js-newsList').data('count');
	$('.js-newsList li:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');
	$('.js-newsMoreBtn').on('click', function() {
		$('.js-newsList li.is-hidden').slice(0, moreNum).removeClass('is-hidden');
		if ($('.l-news__list li.is-hidden').length == 0) {
			$('.js-newsMoreBtn').fadeOut();
		}
	});
}
