import slick from 'slick-carousel';

if($('#js-kvSlider').length){
	let _slider = $('#js-kvSlider')
	$(_slider).on('init', function () {
		$('.slick-slide[data-slick-index="0"]').addClass('slick-animation');
	}).slick({
			autoplay: true,
			infinite: true,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			speed: 1000,
			autoplaySpeed: 3000,
			pauseOnFocus: false,
			pauseOnHover: false
		})
		.on({
			beforeChange: function (event, slick, currentSlide, nextSlide) {
				$('.slick-slide', this).eq(nextSlide).addClass('slick-animation');
				$('.slick-slide', this).eq(currentSlide).addClass('stop-animation');
			},
			afterChange: function () {
				$('.stop-animation', this).removeClass('stop-animation slick-animation');
			},
		});
}

if($('#js-navigationResearchSlider').length){
	let _slider = $('#js-navigationResearchSlider')
	_slider.slick({
		autoplay: false,
		infinite: true,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		speed: 1000,
		appendArrows: $('.l-navigationDrawer__researchControls'),
		prevArrow: '<span class="l-navigationDrawer__researchControlPrev"></span>',
		nextArrow: '<span class="l-navigationDrawer__researchControlNext"></span>',
	});
}
