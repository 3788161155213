if($('.js-popupBtn').length){
	$('.js-popupBtn').click(function(){
		let _e = $(this);
		let _imgSrc = _e.data('img')
		if(_imgSrc === undefined){
			_imgSrc = _e.parent().find('.p-mapSection__img').attr('src')
		}
		$('.js-popupImg').attr('src', _imgSrc)
		$('.js-popup').fadeIn()
		$('html').addClass('is-fixed')
	})
	$('.js-popupCloseBtn').click(function(){
		$('.js-popup').fadeOut()
		$('html').removeClass('is-fixed')
		$('.js-popupImg').attr('src', '')
	})
}
