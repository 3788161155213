const _menuBtn = $('#js-hamburger')
const _header = $('#js-header')
const _navigation = $('#js-navigation')
const _languageText = $('#js-language')
_menuBtn.click(function(){
	_header.toggleClass('is-open')
	_menuBtn.toggleClass('is-open')
	_navigation.toggleClass('is-open')
	if(!($('.js-searchDrawerOpenBtn').hasClass('is-active'))){
		$('html').toggleClass('is-fixed')
	}else if($('html').hasClass('mobile')){
		$('.l-header__searchDrawer').fadeOut()
		$('.l-header__searchDrawerBg').fadeOut()
		$('.js-searchDrawerOpenBtn').removeClass('is-active')
		$('html').removeClass('is-fixed')
	}
})

$('.js-searchDrawerOpenBtn').click(function(){
	if($(this).hasClass('is-active')){
		$('.l-header__searchDrawer').fadeOut()
		$('.l-header__searchDrawerBg').fadeOut()
		$('.js-searchDrawerOpenBtn').removeClass('is-active')
		if(!(_menuBtn.hasClass('is-open'))){
			$('html').removeClass('is-fixed')
		}
	}else{
		$('.l-header__searchDrawer').fadeIn()
		$('.l-header__searchDrawerBg').fadeIn()
		$('.js-searchDrawerOpenBtn').addClass('is-active')
		$('html').addClass('is-fixed')
	}
})
$('.js-searchDrawerCloseBtn').click(function(){
	$('.l-header__searchDrawer').fadeOut()
	$('.l-header__searchDrawerBg').fadeOut()
	$('.js-searchDrawerOpenBtn').removeClass('is-active')
	if(!(_menuBtn.hasClass('is-open'))){
		$('html').removeClass('is-fixed')
	}
})

_languageText.click(function(){
	$('.l-language__list').fadeToggle()
})
// $(window).on('load resize', function(){
// 	$('.l-header__searchDrawer').fadeOut()
// 	$('.l-header__searchDrawerBg').fadeOut()
// 	$('html').removeClass('is-fixed')
// })

// animation
// var _timer = '';
// $(window).on('load resize', function(){
// 	if (_timer) {
// 		clearTimeout(_timer);
// 	}
// 	_timer = setTimeout(function(){
// 		if(!$('html').hasClass('is-fixed')){
// 			let _pos = 0;
// 			function ScrollAnime() {
// 				var _elemTop = $('.l-main').offset().top;
// 				var _scroll = $(window).scrollTop();
// 				var _height = _header.outerHeight(true);
// 				if(_scroll == _pos) {
// 				}else if(_elemTop > _scroll || 0 > _scroll - _pos){
// 					_header.removeClass('is-up');
// 					_header.addClass('is-down');
// 				}else{
// 					_header.removeClass('is-down');
// 					_header.addClass('is-up');
// 				}
// 				_pos = _scroll;
// 			}
// 			if($('body').hasClass('p-home')){
// 				let _height = $(window).outerHeight(true);
// 				let _footer = $('.p-homeAbout__heading').offset().top;
// 				let scroll = $(window).scrollTop() + $(window).height();
// 				$(window).scroll(function(){
// 					if($(this).scrollTop() + _height > _footer){
// 						_header.addClass('is-scroll')
// 						ScrollAnime();
// 					} else {
// 						_header.removeClass('is-scroll')
// 					}
// 				})
// 			} else {
// 				$(window).scroll(function(){
// 					ScrollAnime();
// 				});
// 			}
// 		}
// 	}, 1000);
// });
