'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { height } from './view/_height';
import { smoothScroll } from './view/_smoothScroll';
import { scroll } from './view/_scroll';
import { header } from './view/_header';
import { accordion } from './view/_accordion';
import { slider } from './view/_slider';
import { popup } from './view/_popup';
import { news } from './view/_news';
