const _accordion = $('.js-questionItem')
_accordion.click(function(){
	let _e = $(this)
	_e.find('.js-questionTitle').toggleClass('is-active')
	_e.find('.js-questionContents').slideToggle();
})
// $(window).on('load', function(){
// 	if(_btn.length){
// 		let _1stAccordionBtn = _btn.eq(0)
// 		_1stAccordionBtn.addClass('is-active')
// 		_1stAccordionBtn.next('.js-accordion__contents').show();
// 	}
// })

// const _sidebarBtn = $('.js-sidebarTitle')
// _sidebarBtn.click(function(){
// 	let _e = $(this)
// 	_e.toggleClass('is-active')
// 	_e.next('.js-sidebarList').slideToggle();
// })

// $('.js-newsSidebarTitle').click(function(){
// 	let _e = $(this)
// 	_e.toggleClass('is-active')
// 	_e.next('.p-newsSidebar__subList').slideToggle();
// })
// $('.js-archiveTitle').click(function(){
// 	let _e = $(this)
// 	_e.toggleClass('is-active')
// 	_e.next('.js-archiveList').slideToggle();
// })
